/* eslint-disable no-use-before-define */
import { Settings } from "@algolia/client-search";
import { publicSearchClient, searchClient } from "./clients";
import { LocalizableAttributes } from "./types";

//++++++++++++++++++++++++++++++++++++++++
// Algolia Shop Collection Products Index
//++++++++++++++++++++++++++++++++++++++++

/**
 * Algolia SearchIndex Settings for Shop Collection Products
 */
export const shopCollectionProductsIndexSettings: Settings = {
  customRanking: ["asc(shopCollectionDisplayRank)"],
  attributeForDistinct: "shopCollectionSlug",
  attributesForFaceting: [
    "tags",
    "shopCollectionSlug",
    "forSale",
    "isSubscribable",
  ],
  distinct: false,
};

/**
 * Algolia SearchIndex for Shop Collection Products for use at build time only
 *
 * This index is intended to support Shop and Product Listing Pages.
 *
 * @returns `AlgoliaShopCollectionProduct[]`
 */
export const shopCollectionProductsIndex = searchClient.initIndex(
  standardizeIndexName("shopCollectionProducts"),
);

/**
 * Shop Collection Products Index for use in the client
 *
 * _To limit the number of `AlgoliaShopCollectionProduct`s returned per Shop Collection,
 * set the `distinct` value to 1 or higher at search time_
 */
export const publicShopCollectionProductsIndex = publicSearchClient.initIndex(
  standardizeIndexName("shopCollectionProducts"),
);

//++++++++++++++++++++++++
// Algolia Products Index
//++++++++++++++++++++++++
/**
 * Algolia SearchIndex Settings for Products
 *
 * @param {string | string[]} languageCodes Language codes to use for localizing localizable `searchableAttributes`
 * @example ["esp", "jpn"]
 *
 * @returns {SearchIndex} Algolia SearchIndex
 */
export const productsIndexSettings = (languageCodes: string[]): Settings => ({
  attributesForFaceting: ["forSale"],
  customRanking: ["asc(productDisplayRank)"],
  searchableAttributes:
    localizedProductsIndexSearchableAttributes(languageCodes),
});

/**
 * Returns an array of Products Index searchable attributes localized according to the provided language code(s)
 *
 * @param {string | string[]} languageCodes Language code(s) to use for localizing localizable `searchableAttributes`
 * @example "esp" or ["eng", "esp", "jpn"]
 *
 * @returns {string[]} Array of Products Index searchable attributes, localized where appropriate
 */
export const localizedProductsIndexSearchableAttributes = (
  languageCodes: string | string[],
): string[] => {
  const searchableAttributes = (languageCode: string) => [
    `name.${languageCode}`,
    `description.${languageCode}.children.text`,
  ];

  return localizeSearchableAttributes(searchableAttributes, languageCodes);
};

/**
 * Algolia SearchIndex for Products for use at build time only
 *
 * This index intended to support site-wide search and Algolia Recommend functionality.
 *
 * @returns `AlgoliaProduct[]`
 */
export const productsIndex = searchClient.initIndex(
  standardizeIndexName("products"),
);

export const publicProductsIndex = publicSearchClient.initIndex(
  standardizeIndexName("products"),
);

//++++++++++++++++++++++++
// Algolia Cafes Index
//++++++++++++++++++++++++

/**
 * Algolia SearchIndex Settings for Cafes
 */
export const cafesIndexSettings = (languages: string[]): Settings => ({
  attributeForDistinct: `name.${languages[0]}`,
  attributesForFaceting: languages.flatMap((lang) =>
    cafesIndexAttributesForFaceting(lang),
  ),
  searchableAttributes: languages.flatMap((lang) =>
    cafesIndexSearchableAttribute(lang),
  ),
  attributesToRetrieve: [
    "address",
    "country",
    "_geoloc",
    "geoLocation",
    "hours",
    "image",
    "name",
    "objectID",
    "region",
    "slug",
  ],
  ranking: ["geo"],
  removeWordsIfNoResults: "allOptional",
  typoTolerance: true,
});

export const cafesIndexAttributesForFaceting = (lang: string) => [
  `searchable(name.${lang})`,
  `searchable(region.${lang})`,
  "searchable(address.district)",
  "searchable(address.locality)",
  "searchable(address.postalCode)",
  "searchable(address.street)",
  "searchable(address.countryCode)",
  "searchable(country)",
  "searchable(_geoloc)",
];

export const cafesIndexSearchableAttribute = (lang: string) => [
  `name.${lang}`,
  `region.${lang}`,
  "address.district",
  "address.locality",
  "address.postalCode",
  "address.street",
  "address.countryCode",
  "country",
  "_geoloc",
];

/**
 * Algolia SearchIndex for Cafes
 *
 * The following options are set at index time; please note that others
 * may have been set via Algolia's Dashboard:
 *   - attributesForFaceting
 *   - searchableAttributes
 *   - attributesToRetrieve
 *   - minProximity
 *   - typoTolerance
 *
 * @returns `AlgoliaCafe[]`
 */
export const cafesIndex = searchClient.initIndex(standardizeIndexName("cafes"));
export const publicCafesIndex = publicSearchClient.initIndex(
  standardizeIndexName("cafes"),
);

//++++++++++++++++++
// Shared Functions
//++++++++++++++++++

/**
 * Prefixes provided `indexBaseName` with the name of the Sanity dataset used in the build
 *
 * Intended to establish and enforce a naming convention that permits the use of a single
 * Algolia project to manage indices across different locales/development environments.
 *
 * @param { string } indexBaseName Base name to prefix with Sanity dataset name
 * @example "shopCollectionProducts"
 *
 * @returns { string } `indexBaseName` prefixed with Sanity dataset name
 * @example "us-production-shopCollectionProducts"
 */
export function standardizeIndexName(indexBaseName: string): string {
  return `${process.env.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET}-${indexBaseName}`;
}

/**
 * Returns a flattened/deduplicated array of searchable attributes, localized where appropriate
 *
 * Blue Bottle includes all translations of Sanity localized attributes when indexing Sanity
 * records in Algolia. This demands that:
 *   1. At build time, when assigning a localized attribute to `searchableAttributes`, we must pass all
 *      variations of the localized attribute (e.g. name.eng, name.jpn, name.esp)
 *      corresponding to the languages that are supported for the given environment (e.g. us-production,
 *      ca-production)
 *   2. At query time, we must set `refineSearchableAttributes` to the variation of the localized
 *      `searchableAttributes` that correspond to the language that the user has selected
 *
 * This function is intended to provide a common reference when performing both of these steps. Specifically:
 *   - When setting `searchableAttributes` at build time, one can pass an array of language codes from
 *     the `<root>/nextjs/localization.ts` file to create a flattened/deduplicated array of `searchableAttributes`
 *     that is inclusive of all variations of localized attributes plus any non-localized attributes.
 *   - Then, at query time, one can set `refineSearchableAttributes` to the return value of this function
 *     when passing a single language code corresponding to the language that the guest has selected. This
 *     ensures that results are optimized to return hits that match in the desired language.
 *
 * @param {string | string[]} languageCodes Language code(s) to use for localizing localizable `searchableAttributes`
 * @example "esp" or ["eng", "esp", "jpn"]
 *
 * @returns {string[]} Array of searchable attributes, localized where appropriate
 */
function localizeSearchableAttributes(
  localizableAttributes: LocalizableAttributes,
  languageCodes: string | string[],
): string[] {
  const localizedSearchableAttributes = [languageCodes]
    .flat()
    .flatMap(localizableAttributes);

  // Remove any duplicate, non-localizable attributes
  return Array.from(new Set(localizedSearchableAttributes));
}
