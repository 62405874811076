import {
  ConversionCopy,
  ShopCardProps,
} from "@bluebottlecoffee/design-system/components";
import { toHTML } from "@portabletext/to-html";
import { Product } from "@bluebottlecoffee/design-system/components/lib/types";
import {
  Product as ProductChord,
  ProductVariant,
} from "@chordcommerce/react-autonomy";
import { publicShopCollectionProductsIndex } from "../algolia/indices";
import {
  AlgoliaRecommendProduct,
  AlgoliaShopCollectionProduct,
} from "../algolia/types";
import { productPage } from "../link-builders";
import {
  ImageResponsive,
  ShippingCopy as ShippingCopySchema,
  ShopCardButton,
  ShopCardDetail,
} from "../sanity-schema";
import { ShopCardWithSlug } from "../sanity/shop-card-queries";
import { ImageType, toImageProps } from "./image";
import { toVariantProps } from "./variant";
import { ConversionSchema, toProductProps } from "./conversion";
import { SourceProductRec } from "./product-recs";

export function isValidShopCard(
  product: AlgoliaShopCollectionProduct,
): boolean {
  const { shopCard } = product;
  const button: ShopCardButton = shopCard?.button;
  const detail: ShopCardDetail = shopCard?.detail;
  return (
    !!product.name &&
    !!product.primaryShopCollectionName &&
    shopCard &&
    button &&
    !!button.activeText &&
    !!button.inactiveText &&
    detail &&
    !!detail.image &&
    !!detail.tagline
  );
}
const getSearchQueryString = (
  product: AlgoliaShopCollectionProduct | ConversionSchema,
  props: {
    position?: number;
    queryID?: string;
    sourceIndexName?: string;
    search?: boolean;
  },
) => {
  const defaultIndexName = publicShopCollectionProductsIndex.indexName;
  const queryStrings = [
    `a_idx=${props?.sourceIndexName ?? defaultIndexName}`,
    `a_oID=${(product as AlgoliaShopCollectionProduct)?.objectID}`,
    `a_qID=${props?.queryID}`,
    `a_pos=${props?.position}`,
  ];

  if (props?.search) queryStrings.push(`search=true`);

  return `?${queryStrings.join("&")}`;
};

const productPlaceholderImage = "/coffee-bag_whrevt.png";
const circleDetailPlaceholderImage =
  "/ar_1:1,b_rgb:ffffff,bo_0px_solid_rgb:ffffff,c_crop,fl_clip,g_center,h_1000,r_1000,w_1000/v1630525715/roasted-beans_wfou4v.png";

const toShopCardImages = ({ data, lang }) => {
  const detail = data.shopCard?.detail;
  const productCardProps = data.shopCard?.product;
  const defaultProductImage: ImageResponsive | undefined =
    (data.images?.length ?? 0) > 0 ? data.images[0] : undefined;

  const productImage: ImageType | undefined =
    productCardProps?.image ?? defaultProductImage;

  const detailImage: ImageType | undefined = detail?.image;

  const productImageSource: string = productImage
    ? toImageProps(productImage, lang).src
    : productPlaceholderImage;
  const detailImageSource: string = detailImage
    ? toImageProps(detailImage, lang).src
    : circleDetailPlaceholderImage;

  return [
    {
      src: detailImageSource,
      altText: detail?.image?.altText?.[lang] ?? "",
    },
    {
      src: productImageSource,
      altText: productCardProps?.image?.altText?.[lang] || "",
    },
  ];
};

interface toShopCardDetailProps {
  data: AlgoliaShopCollectionProduct | ConversionSchema;
  showShopcardDetail: boolean;
  lang: string;
}

const toShopCardDetail = ({
  data,
  showShopcardDetail,
  lang,
}: toShopCardDetailProps) => {
  if (!showShopcardDetail) return null;

  const recommendationCopy =
    data?.quickSubscribeCard.detail?.recommendedCopy[lang];
  const exampleCopy = data?.quickSubscribeCard.detail?.exampleCopy[lang];

  return {
    recommendationCopy: recommendationCopy ? toHTML(recommendationCopy) : null,
    exampleCopy: exampleCopy ? toHTML(exampleCopy) : null,
  };
};

type ProductProps =
  | AlgoliaShopCollectionProduct
  | ConversionSchema
  | AlgoliaRecommendProduct
  | SourceProductRec;

export const toTrackShopCards = (
  product: ProductProps,
  lang: string,
): { product: ProductChord } => {
  const variant = product.variants[0];
  const [image] = variant.images?.length ? variant.images : product.images;

  return {
    product: {
      metadata: {
        brand: product?.brandName ?? null,
      },
      variant: {
        slug: product.slug.current,
        sku: variant.sku,
        price: (variant.price / 100).toString(),
        images: [
          {
            alt: image.desktop.altText[lang],
            type: image.desktop.source.type,
            largeUrl: image.desktop.source.url,
          },
        ],
        description: variant.label.eng,
        name: product.name.eng,
      } as ProductVariant,
    },
  };
};

interface ToSHopCardPropsType {
  data: AlgoliaShopCollectionProduct | ConversionSchema;
  copy?: ConversionCopy;
  shippingCopy?: ShippingCopySchema;
  lang: string;
  region: string;
  showShopcardDetail?: boolean;
  props?: {
    sourceIndexName: string;
    queryID?: string;
    position?: number;
    search?: boolean;
  };
}

export type ShopCardPropsWithSlug = Omit<
  ShopCardProps,
  | "subscribeProduct"
  | "addToCart"
  | "useVariantAvailability"
  | "createStockRequest"
> & {
  productSlug: ShopCardWithSlug["productSlug"];
  product: Product;
  trackShopcardData?: { product: ProductChord };
  objectID?: string;
};

export function toShopCardProps({
  data,
  copy,
  shippingCopy,
  lang,
  region,
  props,
  showShopcardDetail,
}: ToSHopCardPropsType): ShopCardPropsWithSlug {
  const inactive = !data.forSale;
  const productSlug = data.shopCard?.productSlug;
  const detail = data.shopCard?.detail;
  const button = data.shopCard?.button;

  try {
    // Query params are used to add algolia analytics to the product link
    // When the `PageViewed` event is fired on the product page, the query params
    // are are parsed by the `assignAlgoliaProps` middleware and added to the
    // Segment payload
    const enrichedLink =
      productPage({ region, lang, slug: productSlug }) +
      getSearchQueryString(data, props);

    const {
      staticShippingCopy,
      subscriptionBenefitsCopy,
      subscriptionPriceDisclaimerCopy,
      subscriptionFreeShippingCopy,
    } = shippingCopy ?? {};

    return {
      name: data.name[lang],
      collectionName: data.primaryShopCollectionName?.[lang] ?? "",
      link: enrichedLink,
      productSlug, // This is used for analytics  to track when a product is clicked
      images: toShopCardImages({ data, lang }),
      tagline: detail?.tagline[lang] ?? null,
      infoLine1: detail?.infoLine1?.[lang] ?? null,
      infoLine2: detail?.infoLine2?.[lang] ?? null,
      copy,
      shippingCopy: {
        staticShippingCopy: staticShippingCopy?.[lang],
        subscriptionBenefitsCopy: toHTML(subscriptionBenefitsCopy?.[lang]),
        subscriptionPriceDisclaimerCopy:
          subscriptionPriceDisclaimerCopy?.[lang],
        subscriptionFreeShippingCopy: subscriptionFreeShippingCopy?.[lang],
      },
      cta: {
        inactive,
        activeText: button.activeText[lang],
      },
      bestValueVariant: toVariantProps(
        (data as AlgoliaShopCollectionProduct)?.bestValueVariant ??
          data.variants.sort((v) => v.price)[0],
        lang,
      ),
      bannerColor: data.shopCard?.product?.banner?.bannerColor ?? "grey",
      bannerText: data.shopCard?.product?.banner?.text?.[lang] ?? null,
      product: toProductProps(data as any, lang),
      detail: toShopCardDetail({ data, showShopcardDetail, lang }),
      trackShopcardData: toTrackShopCards(data, lang),
    };
  } catch (e) {
    throw new Error(
      `Product "${data.name[lang]}" threw: ${e}. Make any updates and then re-index Algolia for edits to appear.`,
    );
  }
}
