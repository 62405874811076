import { CartReminder as CartReminderComponent } from "@bluebottlecoffee/design-system/components";
import { FunctionComponent, useEffect, useState } from "react";
import cookie from "js-cookie";
import { useCart, useCheckout } from "@chordcommerce/react-autonomy";
import { Currency } from "dinero.js";
import { useRouter } from "next/router";
import { Cart } from "../lib/sanity-schema";
import {
  cartReminderLines,
  discountTotal,
  getPromoLabels,
  isFreeShipping,
} from "../lib/utils";
import {
  toOrderSummaryProps,
  toLineItemsProps,
  continueToCheckout,
} from "../lib/transformers/cart";

interface CartReminderProps {
  cartCopy?: Cart;
  lang: string;
}

export const CartReminder: FunctionComponent<CartReminderProps> = ({
  cartCopy,
  lang,
}) => {
  const [show, setShow] = useState(false);
  const { cart, isLoaded, modifyCart } = useCart();
  const { prepareCheckout } = useCheckout();
  const { asPath } = useRouter();

  useEffect(() => {
    const isNotDismissCookie =
      cookie.get("cartReminder") && cookie.get("cartReminder") !== "dismiss";

    /* If the user enters the site and has no products, this prevents the cart reminder 
    from opening when products are added for the first time. Additionally, it provides a 
    half-day window so that the cart reminder does not appear if the user stays on the 
    page during that time. */
    if ((!cart.lineItems?.length && isLoaded) || isNotDismissCookie) {
      cookie.set("cartReminder", "no show", { expires: 0.5 });
    }

    /* This sets the cookie so that after the user has left the site and returns after 
    30 minutes, the cart reminder will appear */
    const setCartReminderCookie = () => {
      if (isNotDismissCookie) {
        cookie.set("cartReminder", "no show (beforeunload)", {
          expires: 1 / 48,
        });
      }
    };

    window.addEventListener("beforeunload", setCartReminderCookie);
    return () =>
      window.removeEventListener("beforeunload", setCartReminderCookie);
  }, [isLoaded]);

  useEffect(() => {
    setShow(cookie.get("cartReminder") === undefined);
  }, []);

  const pagesToExcludeFromRendering: string[] = ["/cart"];
  const shouldNotRender = pagesToExcludeFromRendering.some((page) =>
    asPath.includes(page),
  );

  if (!cartCopy || shouldNotRender) return null;

  return (
    <CartReminderComponent
      show={show}
      onDismiss={() => {
        /* When the guests dismiss the cart reminder, it won't be shown again for 3 days. */
        cookie.set("cartReminder", "dismiss", { expires: 3 });
      }}
      isLoaded={isLoaded}
      copy={{
        title: "Welcome Back, We Saved Your Cart!", // TODO!
        quantity: "Quantity:", // TODO!
        isSubscriptionLabel: "subscription", // TODO!
      }}
      cartReminderLines={cartReminderLines(cart)}
      orderSummaryProps={{
        ...toOrderSummaryProps(cartCopy.orderSummary, lang),
        lineItems: {
          ...toLineItemsProps(cartCopy.orderSummary.lineItems, lang),
          credit:
            Number(cart.totalApplicableStoreCredit) !== 0.0
              ? cart.displayTotalApplicableStoreCredit
              : undefined,
          currency: cart.currency as Currency,
          discountTotal: discountTotal(cart),
          estimatedTotalCost: cart.displayOrderTotalAfterStoreCredit
            ? cart.displayOrderTotalAfterStoreCredit
            : undefined,
          freeShipping: isFreeShipping(cart),
          grossSubtotal: cart.displayItemTotal
            ? cart.displayItemTotal
            : cartCopy.orderSummary.lineItems.tbd[lang],
          promoLabels: getPromoLabels(cart),
          shippingCost:
            cart.shipTotal === "0.0" ? undefined : cart.displayShipTotal,
          variantsTotal: cart.totalQuantity ? cart.totalQuantity : undefined,
        },
        subsPresent: false,
        ctaClick: continueToCheckout({
          modifyCart,
          prepareCheckout,
        }),
        giftMessageForm: undefined,
        promoForm: undefined,
        cartView: "reminder",
      }}
    />
  );
};
